import React, {useEffect, useState } from "react";
 
 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
 

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowlanguages_view ,
    Allowlanguages_add ,
    Allowlanguages_edit ,
    Allowlanguages_delete 
} from './../../Permissions.js';

 

const Addlanguage = () => {

  
const[languageid] = useState(UrlSplitter(4));
 
 const words = ['Hello','Home'];
  const [resultwords,setresultwords ] = useState({});




   const handleUserInput = (e) => {
             var inputname = e.target.name;
            var inputvalue = e.target.value;
 var inputkey =  e.target.attributes.getNamedItem('data-word').value ;


 const inputs = document.getElementsByName("translations[]");
 var wordstoupdate=[]
for (let i = 0; i < inputs.length; i++) {
    
 var key =  (inputs[i].attributes.getNamedItem('data-word').value) ;
 var value =  (inputs[i].value) ;

if(inputkey == key){
    value = inputvalue;
}

 
var data={word:key ,translation : value};
wordstoupdate.push(data)    
wordstoupdate[key]=value

}
setresultwords(wordstoupdate)

             
        }
 
 const Getlanguage = async (e) => {
   

     var postdata={_id:languageid};

     const response = await fetch(Url+"getlanguage", {
        method: "POST",
        headers: {
          "Code-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

    (async function() {
              var languagedata=data.data;

                
 
 
                            
                             
                        })()




         
          } else{
              //   
          }

           
       })

 

 } 


 
 const Getlanguagewords = async (e) => {
   

     var postdata={languageid:languageid};

     const response = await fetch(Url+"getlanguagewords", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              
     

            setresultwords(data.data)
         
          } else{
              //   
          }

           
       })

 

 } 

 
  const Updatelanguagewords = async (e) => {

 

   
      


  
 const inputs = document.getElementsByName("translations[]");
 var wordstoupdate=[]
for (let i = 0; i < inputs.length; i++) {
    
 var key =  (inputs[i].attributes.getNamedItem('data-word').value) ;
 var value =  (inputs[i].value) ;

 
var data={word:key ,translation : value};
wordstoupdate.push(data)    
wordstoupdate[key]=value

}
 
  
 

         
      var postdata={languageid:languageid , wordstoupdate :wordstoupdate };
 
        
        
  
      const response = await fetch(Url+"updatelanguagewords", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }) 
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

   
  };


    
useEffect(() => {
    Getlanguagewords(languageid)
if(typeof languageid != 'undefined' && languageid !=''){
    Getlanguage(languageid);
}

if(typeof languageid != 'undefined' && languageid !=''){
    checkpermissionredirect(Allowlanguages_edit)
}else{
    checkpermissionredirect(Allowlanguages_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header name={typeof languageid !== 'undefined'?"Edit Language":"Add Language"} />

         <Sidebar activePage="Languages"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-code container-fluid">
                
                              <Pagetitle activePage={typeof languageid !== 'undefined'?"Edit Language":"Add Language"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-name">{typeof languageid !== 'undefined'?"Edit Language":"Add Language"}</h4>
                </div>
                <div className="card-code">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        


                        <table width="100%" cellpadding="5">

                       {  words.map((word) => (

                            <tr> <td width="50%"> {word}</td> <td  width="50%"> <input type="text" data-word={word} onChange={(e)=>handleUserInput(e)}  name="translations[]" value={resultwords[word]}  className="form-control" /> </td> </tr> 


                            ))}


                        </table>
                    </div>
                             <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updatelanguagewords}>Submit</a>
                         </div>
                           

                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addlanguage;