import React, {useEffect, useState } from "react";
 
 import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
 

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowplans_view ,
    Allowplans_add ,
    Allowplans_edit ,
    Allowplans_delete 
} from './../../Permissions.js';

 

const Addplan = () => {

  
const[planid] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
const[shortdesc, setShortDesc] = useState(""); 

const[desc, setDesc] = useState(""); 
 const[price, setPrice] = useState(""); 

 
const[status, setStatus] = useState(1); 


 
 

const [namereqerror, setnamereqerror] = useState("");
const [shortdescreqerror, setshortdescreqerror] = useState("");
 const [descreqerror, setdescreqerror] = useState("");

 const [pricereqerror, setpricereqerror] = useState("");

 
 
   const modules =  {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ]
  } 
 
 const [formats ]=   [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ] 


const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'name':
            setName(value);
            setnamereqerror('');
            break;
        case 'desc':
            setDesc(value);
             setdescreqerror('');
            break;
          case 'shortdesc':
            setShortDesc(value);
             setshortdescreqerror('');
            break;
        
         case 'price':
            setPrice(value);
             setpricereqerror('');
            break;

         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }
   


 
 const Getplan = async (e) => {
   

     var postdata={_id:planid};

     const response = await fetch(Url+"getplans", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

               var plandata=data.data;

                    
                setName(plandata.title);
                   setShortDesc(plandata.shortdesc);
                   setDesc(plandata.desc);
    
  
                            
                             setPrice(plandata.price);
                     



         
          } else{
              //   
          }

           
       })

 

 } 

 


 
  const Updateplan = async (e) => {

    setnamereqerror('');
     setdescreqerror('');
      setshortdescreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(name) == false){
        setnamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(desc) == false){
        setdescreqerror(requiredErrormessage)
        validationerror=1;
    } 
       

         if(checkEmptyUndefined(shortdesc) == false){
        setshortdescreqerror(requiredErrormessage)
        validationerror=1;
    } 
       
       if(checkEmptyUndefined(price) == false){
        setpricereqerror(requiredErrormessage)
        validationerror=1;
    } 


       


 
     if(validationerror == 0){ 

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


            let formData = new FormData()
            formData.append('name', name)
 
            formData.append('desc', desc)
            formData.append('shortdesc', shortdesc)
             formData.append('status', statusupdate)
            formData.append('_id', planid)

 

 
        
  
      const response = await fetch(Url+"updateplan", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };


    
useEffect(() => {
 if(typeof planid != 'undefined' && planid !=''){
    Getplan(planid);
}

if(typeof planid != 'undefined' && planid !=''){
    checkpermissionredirect(Allowplans_edit)
}else{
    checkpermissionredirect(Allowplans_add)

}

  
  },[] ); 

   return (
<div id="app">
   <Header title={typeof planid !== 'undefined'?"Edit Plan ":"Add Plan "} />
   <Sidebar activePage="Plans"  activeChildPage="Add" />
   <div id="main">
      <Navbar />
      <div className="main-content container-fluid">
         <Pagetitle activePage={typeof planid !== 'undefined'?"Edit Plan ":"Add Plan "}/>
         <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">{typeof planid !== 'undefined'?"Edit Plan ":"Add Plan "}</h4>
                  </div>
                  <div className="card-content">
                     <div className="card-body">
                        <form className="form form-horizontal" autocomplete="off">
                           <div className="form-body">
                              <div className="row">
                                 <div className="col-md-4">
                                    <label>Name <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                                       onChange={(e) => handleUserInput(e)}
                                    required/>
                                    <p className="req-error-message">{namereqerror}</p>
                                 </div>

                                 <div className="col-md-4">
                                    <label>Short Desc <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <ReactQuill theme="snow" value={shortdesc}      modules={modules}
                                       formats={formats}  onChange={setShortDesc} />
                                    <p className="req-error-message">{shortdescreqerror}</p>
                                 </div>


                                 <div className="col-md-4">
                                    <label>Desc <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <ReactQuill theme="snow" value={desc}      modules={modules}
                                       formats={formats}  onChange={setDesc} />
                                    <p className="req-error-message">{descreqerror}</p>
                                 </div>

                                 <div className="col-md-4">
                                    <label>Price <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <input className="form-control"  value={price}  type="text" name="price" placeholder="Price"  
                                       onChange={(e) => handleUserInput(e)}
                                    required/>
                                    <p className="req-error-message">{pricereqerror}</p>
                                 </div>

                                   
                                 <div className="col-md-4">
                                    <label>Status <span className="field-required">*</span></label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                                    <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                                    <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                                    </select>
                                 </div>
                                 <div className="col-sm-12 d-flex justify-content-end">
                                    <a  className="btn btn-primary me-1 mb-1" onClick={Updateplan}>Submit</a>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   </div>
</div>
   
 
  );
};
export default Addplan;