import React from "react";
 
 
//import {SiteLogo} from './Frontapi.js';

 
  
  const GetAssetsurl = async(param) => { 

 	  	return  'http://localhost/buffersite/assets/' ;

  }


  const GetDynamicImagebaseurl = async(param) => { 

 if(window.location.origin === 'http://localhost:3001'){
 	  	return  'http://localhost/urbancompany/assets/images/' ;

  }else if(window.location.origin === 'http://localhost'){

  return   'http://localhost/assets/images/' ;
}else if(window.location.origin === 'https://umycompany.com'){

	  return   'https://www.umycompany.com/assets/images/' ;

}if(window.location.origin === 'https://www.umycompany.com'){

	  return   'https://www.umycompany.com/assets/images/' ;

}


  }

const ImagesUrlPath = await GetDynamicImagebaseurl();

const AssetsUrlPath = await GetAssetsurl();


 
const Getuserimage=async(profilepicture)=>{

var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'users/'+profilepicture;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

		if(imageexists == 1){
		return  (ImagesUrlPath +'users/'+profilepicture )
		}else{
		//return SiteLogo
		}


 
}


const Getprofileimage=async(profilepicture)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'profile/'+profilepicture;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}


			return  (ImagesUrlPath +'profile/'+profilepicture )
		if(imageexists == 1){
	
		}else{
		//return SiteLogo
		}


 
}


const Getsliderimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'sliders/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'sliders/'+image )
		if(imageexists == 1){
	
		}else{
	//	return SiteLogo
		}


 
}

const Getblogcategoryimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'blogcategory/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'blogcategory/'+image )
		if(imageexists == 1){
	
		}else{
		//return SiteLogo
		}


 
}


const Getblogimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'blog/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'blog/'+image )
		if(imageexists == 1){
	
		}else{
	//	return SiteLogo
		}


 
}



const Getcategoryimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'category/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'category/'+image )
		if(imageexists == 1){
	
		}else{
	//	return SiteLogo
		}


 
}


const Getsubcategoryimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'subcategory/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'subcategory/'+image )
		if(imageexists == 1){
	
		}else{
	//	return SiteLogo
		}


 
}



const Getserviceimage=async(image)=>{


var imageexists = 0;
		const img = new Image();
		img.src = ImagesUrlPath +'service/'+image;

		if (img.complete) {
		imageexists = 1;
		} else {
		img.onload = () => {
		imageexists = 1;
		};

		img.onerror = () => {
		imageexists = 0;
		};
		}

	return  (ImagesUrlPath +'service/'+image )
		if(imageexists == 1){
	
		}else{
	//	return SiteLogo
		}


 
}

export {AssetsUrlPath, Getblogimage, Getcategoryimage,   Getsubcategoryimage ,Getserviceimage, Getblogcategoryimage,Getuserimage,Getprofileimage,Getsliderimage,ImagesUrlPath}