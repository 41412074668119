import React, { useState,useEffect } from "react";
 
import {SiteLogo,baseUrl,Url,CommonNotify} from './../Api.js';
 
 import { Link } from 'react-router-dom';

import { Allowdashboard_view ,
   Allowsettings_view ,
   Allowsettings_edit ,
   Allowusers_view ,
   Allowusers_add ,
   Allowusers_edit ,
   Allowusers_delete ,
   Allowcms_view ,
   Allowcms_edit ,
   Allowemailtemplates_view ,
   Allowemailtemplates_edit ,
 
   Alloworders_view ,
   Alloworders_edit ,
   Allowenquiries_view ,
   Allowstreams_view ,
   Allowschools_view ,
   Allowschools_add ,
   Allowschools_edit ,
   Allowschools_delete ,
 
   Allowadminusers_view,
    Allowadminusers_add,
   Allowrolesview,

   Allowfaqs_view,
   Allowfaqs_add,
      Allowfaqgroups_view,
   Allowfaqgroups_add,
   
   Allowsliders_view,
   Allowsliders_add,


   Allowblog_view,
   Allowblog_add,


   Allowblogcategory_view,


    Allowlanguages_view,
   Allowlanguages_add,


      Allowcurrencies_view,
   Allowcurrencies_add,


   Allowplans_view,
   Allowplans_add,

   Allowcategories_view,
   Allowcategories_add,

    Allowsubcategories_view,
   Allowsubcategories_add,


       Allowservices_view,
   Allowservices_add


} from './../Permissions.js';

 
const Sidebar = ({activePage,activeChildPage}) => {
 

 const  [showAdminuserMenu,setshowAdminuserMenu]=useState(0);

const  [showUserMenu,setshowUserMenu]=useState(0);
const  [showRoleMenu,setshowRoleMenu]=useState(0);
 
 const  [showFaqMenu,setshowFaqMenu]=useState(0);
  const  [showFaqgroupMenu,setshowFaqgroupMenu]=useState(0);



  const  [showSliderMenu,setshowSliderMenu]=useState(0);

  const  [showBlogMenu,setshowBlogMenu]=useState(0);

  const  [showLanguageMenu,setshowLanguageMenu]=useState(0);

  const  [showCurrencyMenu,setshowCurrencyMenu]=useState(0);

  const  [showPlanMenu,setshowPlanMenu]=useState(0);

    const  [showCategoryMenu,setshowCategoryMenu]=useState(0);
   const  [showSubcategoryMenu,setshowSubcategoryMenu]=useState(0);

   const  [showServiceMenu,setshowServiceMenu]=useState(0);


const dashboardActive = (activePage === 'Dashboard') ? 'active':''; 

const settingsActive = (activePage === 'Settings') ? 'active':''; 
const cmsActive = (activePage === 'Cms') ? 'active':''; 
const emailtemplatesActive = (activePage === 'EmailTemplates') ? 'active':'';


const adminuserActive = (activePage === 'Adminusers') ? 'active':''; 
const rolesActive = (activePage === 'Roles') ? 'active':''; 
 
const userActive = (activePage === 'Users'  ) ? 'active':''; 
 
 
 


const EnquiryActive = (activePage === 'Enquiries') ? 'active':''; 

 

const faqActive = (activePage === 'Faqs'  ) ? 'active':''; 
const faqgroupActive = (activePage === 'Faqgroups'  ) ? 'active':''; 


 const sliderActive = (activePage === 'Sliders'  ) ? 'active':''; 
const blogActive = (activePage === 'Blog'  ) ? 'active':''; 


const languageActive = (activePage === 'Languages'  ) ? 'active':''; 

const currencyActive = (activePage === 'Currencies'  ) ? 'active':''; 


const planActive = (activePage === 'Plans'  ) ? 'active':''; 


const AddActive=   (activeChildPage == 'Add')?'active':''; 
const ListActive=   (activeChildPage == 'List')?'active':''; 
const BlogcategoryActive=   (activeChildPage == 'Blogcategory')?'active':''; 


 
 const categoryActive = (activePage === 'Categories'  ) ? 'active':''; 
 const subcategoryActive = (activePage === 'Subcategories'  ) ? 'active':''; 

 const serviceActive = (activePage === 'Services'  ) ? 'active':''; 



const AddcActive=   (activeChildPage == 'Addcat')?'active':''; 
const ListcActive=   (activeChildPage == 'Listcat')?'active':''; 


const AddsubActive=   (activeChildPage == 'Addsub')?'active':''; 
const ListsubActive=   (activeChildPage == 'Listsub')?'active':''; 



const handleMenu = (e,type)=>{

    
 
switch (type) {
                case 'adminusers':
                    setshowAdminuserMenu(1)
                    break;
                 case 'roles':
                    setshowRoleMenu(1)
                    break;
                      
                     case 'users':
                    setshowUserMenu(1)
                    break;  

                 case 'faqs':
                    setshowFaqMenu(1)
                    break;  

                     case 'faqgroups':
                    setshowFaqgroupMenu(1)
                    break; 

                       case 'sliders':
                    setshowSliderMenu(1)
                    break; 

                      case 'blog':
                    setshowBlogMenu(1)
                    break; 


                     case 'languages':
                    setshowLanguageMenu(1)
                    break; 


                     case 'currencies':
                    setshowCurrencyMenu(1)
                    break; 

                 case 'plans':
                    setshowPlanMenu(1)
                    break; 


                      case 'categories':
                    setshowCategoryMenu(1)
                    break; 

                      case 'subcategories':
                    setshowSubcategoryMenu(1)
                    break; 

                     case 'services':
                    setshowServiceMenu(1)
                    break; 
 
            }            

}
const SetMenuActive = async() => {

    if(activePage  == 'Users'){
     setshowUserMenu(1);
    }
     if(activePage  == 'Roles'){
     setshowRoleMenu(1);
    }

      if(activePage  == 'Adminusers'){
     setshowAdminuserMenu(1);
    }
    if(activePage  == 'Faqs'){
     setshowFaqMenu(1);
    }
     if(activePage  == 'Faqgroups'){
     setshowFaqgroupMenu(1);
    }

      if(activePage  == 'Sliders'){
                         setshowSliderMenu(1)

    }

    if(activePage  == 'Blog'){
                         setshowBlogMenu(1)

    }

      if(activePage  == 'Languages'){
                         setshowLanguageMenu(1)

    }

        if(activePage  == 'Currencies'){
                         setshowCurrencyMenu(1)

    }

    if(activePage  == 'Plans'){
                         setshowPlanMenu(1)

    }

      if(activePage  == 'Categories'){
                         setshowCategoryMenu(1)

    }

      if(activePage  == 'Subcategories'){
                         setshowSubcategoryMenu(1)

    }

       if(activePage  == 'Services'){
                         setshowServiceMenu(1)

    }
      
}



    useEffect(() => {
   SetMenuActive();

  }, []);



  return (

 <div id="sidebar" className='active'>
   <div className="sidebar-wrapper active">
      <div className="sidebar-header">
         <img src={SiteLogo} alt="" srcSet={SiteLogo} />
      </div>
      <div className="sidebar-menu">
        <ul className="menu">
   <li className='sidebar-title'>Main Menu</li>




{(Allowdashboard_view == 1 ) ?
   <li className= {'sidebar-item '+dashboardActive} >
   <Link to={baseUrl+'dashboard'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>Dashboard</span>
   </Link>
   </li> : '' }



{(Allowadminusers_view == 1 ) ?

 <li className={'sidebar-item '+adminuserActive}>
   <a onClick={(e) => handleMenu(e,'adminusers')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-user link-icons-sidebar" width="20"></i> 
   <span>Admin Users</span>
   </a>
   <ul className={showAdminuserMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'adminusers'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Admin Users</span> </Link>
   </li>
   {(Allowadminusers_add == 1 ) ?

   <li >
      <Link to={baseUrl+'adminusers/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Admin User</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }



{(Allowsettings_view == 1 ) ?

<li className={'sidebar-item '+settingsActive}>
<Link to={baseUrl+'settings'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-cog" width="20"></i> 
<span>Settings</span>
</Link>
</li>: '' }



{(Allowrolesview == 1 ) ?


<li className={'sidebar-item '+rolesActive}>


<a onClick={(e) => handleMenu(e,'roles')} data-head="roles"   className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-user-secret" width="20"></i> 
<span>Roles</span>
</a>

  <ul className={showRoleMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'roles'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Roles</span> </Link>
   </li>
   <li >
      <Link to={baseUrl+'roles/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Role</span> </Link>
   </li>
</ul>


</li>: '' }




{(Allowcms_view == 1 ) ?


<li className={'sidebar-item '+cmsActive}>
<Link to={baseUrl+'cms'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-sticky-note" width="20"></i> 
<span>CMS</span>
</Link>
</li>: '' }



{(Allowemailtemplates_view == 1 ) ?


<li className={'sidebar-item '+emailtemplatesActive}>
<Link to={baseUrl+'emailtemplates'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-envelope" width="20"></i> 
<span>Email Templates</span>
</Link>
</li>: '' }




 

 


{(Allowenquiries_view == 1 ) ?


<li className={'sidebar-item '+EnquiryActive}>
<Link to={baseUrl+'enquiries'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-comment" width="20"></i> 
<span>Enquiries</span>
</Link>
</li>: '' }




  

{(Allowusers_view == 1 ) ?

 <li className={'sidebar-item '+userActive}>
   <a onClick={(e) => handleMenu(e,'users')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-user link-icons-sidebar" width="20"></i> 
   <span>Users</span>
   </a>
   <ul className={showUserMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'users'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Users</span> </Link>
   </li>
   {(Allowusers_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'users/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add User</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }


 
 




{(Allowfaqs_view == 1  ||  Allowfaqgroups_view == 1  ) ?

 <li className={'sidebar-item '+faqActive}>
   <a onClick={(e) => handleMenu(e,'faqs')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-question link-icons-sidebar" width="20"></i> 
   <span>Faq</span>
   </a>

 



   



   <ul className={showFaqMenu == 1 ? '' :'display_none'} > 

   <li >
      <Link to={baseUrl+'faqgroup'} className={ListcActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Faq group</span> </Link>
   </li>
   {(Allowfaqgroups_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'faqgroup/add'} className={AddcActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Faq group</span> </Link>
   </li>: '' }


   <li >
      <Link to={baseUrl+'faq'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Faq</span> </Link>
   </li>
   {(Allowfaqs_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'faq/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Faq</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }




{(Allowsliders_view == 1 ) ?

 <li className={'sidebar-item '+sliderActive}>
   <a onClick={(e) => handleMenu(e,'sliders')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-image  link-icons-sidebar" width="20"></i> 
   <span>Slider</span>
   </a>
   <ul className={showSliderMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'sliders'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Slider</span> </Link>
   </li>
   {(Allowsliders_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'sliders/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Slider</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }


 



{(Allowservices_view == 1 || Allowsubcategories_view == 1 || Allowcategories_view == 1 ) ?

 <li className={'sidebar-item '+serviceActive}>
   <a onClick={(e) => handleMenu(e,'services')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-wrench  link-icons-sidebar" width="20"></i> 
   <span>Service</span>
   </a>



   <ul className={showServiceMenu == 1 ? '' :'display_none'} > 

   {(Allowcategories_view == 1 ) ?

 <li >
      <Link to={baseUrl+'categories'} className={ListcActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Category</span> </Link>
   </li>
   : '' }


   {(Allowcategories_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'categories/add'} className={AddcActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Category</span> </Link>
   </li>: '' }



   {(Allowsubcategories_view == 1 ) ?

   <li >
      <Link to={baseUrl+'subcategories'} className={ListsubActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Subcategory</span> </Link>
   </li>: '' }


   {(Allowsubcategories_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'subcategories/add'} className={AddsubActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Subcategory</span> </Link>
   </li>: '' }



   {(Allowservices_view == 1 ) ?

   <li >
      <Link to={baseUrl+'services'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Service</span> </Link>
   </li>: '' }


   {(Allowservices_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'services/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Service</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }




{(Allowblog_view == 1 || Allowblogcategory_view ) ?

 <li className={'sidebar-item '+blogActive}>
   <a onClick={(e) => handleMenu(e,'blog')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-newspaper-o  link-icons-sidebar" width="20"></i> 
   <span>Blog</span>
   </a>
   <ul className={showBlogMenu == 1 ? '' :'display_none'} > 
    <li >
      <Link to={baseUrl+'blogcategory'} className={BlogcategoryActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Blog Category</span> </Link>
   </li>

   <li >
      <Link to={baseUrl+'blog'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Blog</span> </Link>
   </li>
   {(Allowblog_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'blog/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Blog</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }




{(Allowlanguages_view == 1 ) ?

 <li className={'sidebar-item '+languageActive}>
   <a onClick={(e) => handleMenu(e,'languages')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-language  link-icons-sidebar" width="20"></i> 
   <span>Language</span>
   </a>
   <ul className={showLanguageMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'languages'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Language</span> </Link>
   </li>
   {(Allowlanguages_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'languages/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Language</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }





{(Allowcurrencies_view == 1 ) ?

 <li className={'sidebar-item '+currencyActive}>
   <a onClick={(e) => handleMenu(e,'currencies')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-money  link-icons-sidebar" width="20"></i> 
   <span>Currency</span>
   </a>
   <ul className={showCurrencyMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'currencies'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Currency</span> </Link>
   </li>
   {(Allowcurrencies_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'currencies/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Currency</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }

{ (Allowplans_view == 1 ) ?

 <li className={'sidebar-item '+planActive}>
   <a onClick={(e) => handleMenu(e,'plans')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-money  link-icons-sidebar" width="20"></i> 
   <span>Plan</span>
   </a>
   <ul className={showPlanMenu == 1 ? '' :'display_none'} > 
 
   <li >
      <Link to={baseUrl+'plans'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Plan</span> </Link>
   </li>
   {(Allowplans_add == 1 ) ?

   <li >
      <Link  to={baseUrl+'plans/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Plan</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }


</ul>

 



       
      </div>
      <button className="sidebar-toggler btn x amigo-button"><i className="fa fa-eyex"></i></button>
   </div>
</div>


  
  );
};
export default Sidebar;