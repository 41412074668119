import React, {useEffect, useState } from "react";
 
 import {Getsubcategoryimage,TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
   import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowsubcategories_view ,
    Allowsubcategories_add ,
    Allowsubcategories_edit ,
    Allowsubcategories_delete 
} from './../../Permissions.js';

 

const Addsubcategory = () => {

  
const[subcategoryid] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
const[content, setContent] = useState(""); 
 const[link, setLink] = useState(""); 

const[status, setStatus] = useState(1); 


const [image, setImage] = useState({preview:''})

const [category, setCategory] = useState()


const [imagereqerror, setimagereqerror] = useState()
const [namereqerror, setnamereqerror] = useState("");
const [contentreqerror, setcontentreqerror] = useState("");
const [categoryreqerror, setcategoryreqerror] = useState("");
 const [linkreqerror, setlinkreqerror] = useState("");



const [categoriesList, setcategoriesList] = useState([]);



const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'name':
            setName(value);
            setnamereqerror('');
            break;
        case 'content':
            setContent(value);
             setcontentreqerror('');
            break;
         case 'link':
            setLink(value);
             setlinkreqerror('');
            break;
     
         case 'status':
            setStatus(value);
            break;  

             case 'category':
            setCategory(value);
            break;  
        default:
            break;      
    }
 }
 

    const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setImage({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                setImage(img)
            } else {
                setimagereqerror("Invalid Image Format")
             }
        }




 
 const Getsubcategory = async (e) => {
   

     var postdata={_id:subcategoryid};

     const response = await fetch(Url+"getsubcategories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

    (async function() {
              var subcategorydata=data.data;

                    
                setName(subcategorydata.name);
                setLink(subcategorydata.link);
                  setContent(subcategorydata.content);
                    setCategory(subcategorydata.categoryId);
 
 
                             setImage({preview:  subcategorydata.image  }  )
                           
                             
                        })()




         
          } else{
              //   
          }

           
       })

 

 } 



 
  const Updatesubcategory = async (e) => {

    setnamereqerror('');
     setcontentreqerror('');
     setlinkreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(name) == false){
        setnamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(content) == false){
        setcontentreqerror(requiredErrormessage)
        validationerror=1;
    } 

    if(checkEmptyUndefined(link) == false){
        setlinkreqerror(requiredErrormessage)
        validationerror=1;
    }   
      
if(subcategoryid == '' || typeof subcategoryid == 'undefined'){

if(typeof image.data  == 'undefined' || image.data == ''){
                setimagereqerror(requiredErrormessage)
                validationerror=1
            }
    

}

      


 
     if(validationerror == 0){ 

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


            let formData = new FormData()
            formData.append('name', name)
            formData.append('content', content)
            formData.append('link', link)
            formData.append('image', image.data)
            formData.append('status', statusupdate)
            formData.append('_id', subcategoryid)
            formData.append('category', category)

 

 
        
  
      const response = await fetch(Url+"updatesubcategory", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };



 const GetCategorieslist = async ( ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={'currentpage':1,'perpage':10000};

        const response = await fetch(Url+"getcategories", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           setcategoriesList(data.data );
         
         
          } else{
                 
          }

           
       })


 
  };



    
useEffect(() => {

    GetCategorieslist();

if(typeof subcategoryid != 'undefined' && subcategoryid !=''){
    Getsubcategory(subcategoryid);
}

if(typeof subcategoryid != 'undefined' && subcategoryid !=''){
    checkpermissionredirect(Allowsubcategories_edit)
}else{
    checkpermissionredirect(Allowsubcategories_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof subcategoryid !== 'undefined'?"Edit Subcategory":"Add Subcategory"} />

         <Sidebar activePage="Services"  activeChildPage="Addsub" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof subcategoryid !== 'undefined'?"Edit Subcategory":"Add Subcategory"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof subcategoryid !== 'undefined'?"Edit Subcategory":"Add Subcategory"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Title"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{namereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Content <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                           

    <ReactQuill value={content}  onChange={setContent} name="content" placeholder="Content" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />


                    <p className="req-error-message">{contentreqerror}</p>

                        </div>




                          <div className="col-md-4">
                            <label>Category <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">

 
 <select className="form-control"  name="category"  onChange={(e) => handleUserInput(e)}> 
                            <option value="">Choose</option>
                            {categoriesList.map((categorylist) => (
                                <option value={categorylist._id}  selected={categorylist._id == category ? 'selected' :''}  >{categorylist.name}</option>
                             ))}
                            </select>
                       
                    <p className="req-error-message">{categoryreqerror}</p>

                        </div>




                         <div className="col-md-4">
                            <label>Link <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="link" placeholder="Link" value={link} 
                onChange={(e) => handleUserInput(e)}
                required/>
                    <p className="req-error-message">{linkreqerror}</p>

                        </div>



                          <div className="col-md-4">
                            <label>Image <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="file" name="image" placeholder="Link"   
                onChange={(e) => handlePictureChange(e)}
                required/>
                 {(image.preview  != '') ? <img className="img-responsive-amigo"  src ={image.preview}  />  : '' }
                    <p className="req-error-message">{imagereqerror}</p>

                        </div>
                         
                         
                 
                       
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                       
                        <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updatesubcategory}>Submit</a>
                         </div>
                           

                       
                        


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addsubcategory;