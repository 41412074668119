import React, {useEffect, useState } from "react";
 
 import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



 import {Getblogimage,TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
 

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowblog_view ,
    Allowblog_add ,
    Allowblog_edit ,
    Allowblog_delete 
} from './../../Permissions.js';

 

const Addblog = () => {

  
const[blogid] = useState(UrlSplitter(4));

const[title, setTitle] = useState(""); 
const[content, setContent] = useState(""); 
 const[category, setCategory] = useState(""); 

 const[tags, setTags] = useState(""); 

const[status, setStatus] = useState(1); 


const [image, setImage] = useState({preview:''})

const [imagereqerror, setimagereqerror] = useState()


const [titlereqerror, settitlereqerror] = useState("");
const [contentreqerror, setcontentreqerror] = useState("");
const [categoryreqerror, setcategoryreqerror] = useState("");


const[blogcategoryList, setblogcategoryList] = useState([]); 

 
   const modules =  {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ]
  } 
 
 const [formats ]=   [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ] 


const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'title':
            setTitle(value);
            settitlereqerror('');
            break;
        case 'content':
            setContent(value);
             setcontentreqerror('');
            break;
          case 'category':
            setCategory(value);
             setcategoryreqerror('');
            break;
     
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }
 
 const setContentvalue=(value)=>{
    setContent(value)


 }


    const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setImage({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                                setimagereqerror(" ")

                setImage(img)
            } else {
                setimagereqerror("Invalid Image Format")
             }
        }




 
 const Getblog = async (e) => {
   

     var postdata={_id:blogid};

     const response = await fetch(Url+"getblog", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

    (async function() {
              var blogdata=data.data;

                    
                setTitle(blogdata.title);
                   setContent(blogdata.content);
    
    setCategory(blogdata.blogcategoryId);
 
                             setImage({preview: await Getblogimage(blogdata.image) }  )
                           
                             
                        })()




         
          } else{
              //   
          }

           
       })

 

 } 


 const Getblogcategories = async (e) => {
   

     var postdata={active:1};

     const response = await fetch(Url+"getblogcategory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              
        setblogcategoryList(data.data)


         
          } else{
              //   
          }

           
       })

 

 } 




 
  const Updateblog = async (e) => {

    settitlereqerror('');
     setcontentreqerror('');
 
 
    var validationerror = 0;
 
    if(checkEmptyUndefined(title) == false){
        settitlereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(content) == false){
        setcontentreqerror(requiredErrormessage)
        validationerror=1;
    } 
      if(checkEmptyUndefined(category) == false){
        setcategoryreqerror(requiredErrormessage)
        validationerror=1;
    } 
     
      
if(blogid == '' || typeof blogid == 'undefined'){

if(typeof image.data  == 'undefined' || image.data == ''){
                setimagereqerror(requiredErrormessage)
                validationerror=1
            }
    

}

      


 
     if(validationerror == 0){ 

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


            let formData = new FormData()
            formData.append('title', title)
                        formData.append('category', category)

            formData.append('content', content)
             formData.append('image', image.data)
              formData.append('tags', tags)
            formData.append('status', statusupdate)
            formData.append('_id', blogid)

 

 
        
  
      const response = await fetch(Url+"updateblog", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };


    
useEffect(() => {
    Getblogcategories()
if(typeof blogid != 'undefined' && blogid !=''){
    Getblog(blogid);
}

if(typeof blogid != 'undefined' && blogid !=''){
    checkpermissionredirect(Allowblog_edit)
}else{
    checkpermissionredirect(Allowblog_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof blogid !== 'undefined'?"Edit Blog ":"Add Blog "} />

         <Sidebar activePage="Blog"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof blogid !== 'undefined'?"Edit Blog ":"Add Blog "}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof blogid !== 'undefined'?"Edit Blog ":"Add Blog "}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={title}  type="text" name="title" placeholder="Title"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{titlereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Content <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">

                         <ReactQuill theme="snow" value={content}      modules={modules}
        formats={formats}  onChange={setContentvalue} className="textarea-contact-input" />

                            
                    <p className="req-error-message">{contentreqerror}</p>

                        </div>



                        


                          <div className="col-md-4">
                            <label>Image <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="file" name="image" placeholder="Link"   
                onChange={(e) => handlePictureChange(e)}
                required/>
                 {(image.preview  != '') ? <img  src ={image.preview}  />  : '' }
                    <p className="req-error-message">{imagereqerror}</p>

                        </div>
                         
                         
                 
                       
                          <div className="col-md-4">
                            <label>Category <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="category"  onChange={(e) => handleUserInput(e)}> 

                            <option>Choose</option>
                       {  blogcategoryList.map((blogcategory) => (
                         <option value={blogcategory._id}  selected={category == blogcategory._id ? 'selected' :''}  >{blogcategory.title}</option>
                                

                              ))}
                            
                            </select>
                                                                          <p className="req-error-message">{categoryreqerror}</p>

                        </div>


                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           

                        </div>

                       
                        <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updateblog}>Submit</a>
                         </div>
                           

                       
                        


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addblog;